import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as LucideIcons from 'lucide-react';
import { AuthContext } from './contexts/AuthContext';

export const TOOL_PATHS = {
  removeText: '/remove-text',
  convertJpg: '/convert-to-JPG',
  convertPng: '/convert-to-png',
  convertWebp: '/convert-to-webp',
  convertPdf: '/convert-to-pdf',
  crop: '/image-crop',
  compress: '/image-compress',
  resize: '/image-resize',
};

const ToolCard = ({ to, icon, title, description }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(to)}
      className="cursor-pointer"
      role="button"
      aria-label={`Use ${title} tool`}
    >
      <div className="bg-white p-6 rounded-xl transition-all duration-300 hover:shadow-md border border-gray-100 hover:border-purple-100">
        <div className="flex flex-col items-center">
          <div className="mb-4 p-3 rounded-lg bg-purple-50 text-purple-500">
            {icon}
          </div>
          <h2 className="text-lg font-medium text-gray-900 mb-2">{title}</h2>
          <p className="text-sm text-gray-500 text-center">{description}</p>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const { user } = useContext(AuthContext);

  const tools = [
    { to: TOOL_PATHS.removeText, icon: <LucideIcons.Eraser className="w-6 h-6" />, title: 'Remove Text', description: 'Remove watermarks and text from images' },
    { to: TOOL_PATHS.convertJpg, icon: <LucideIcons.Image className="w-6 h-6" />, title: 'Convert to JPG', description: 'Convert images to JPEG format' },
    { to: TOOL_PATHS.convertPng, icon: <LucideIcons.Image className="w-6 h-6" />, title: 'Convert to PNG', description: 'Convert images to PNG format' },
    { to: TOOL_PATHS.convertWebp, icon: <LucideIcons.Image className="w-6 h-6" />, title: 'Convert to WebP', description: 'Convert images to WebP format' },
    { to: TOOL_PATHS.convertPdf, icon: <LucideIcons.File className="w-6 h-6" />, title: 'Convert to PDF', description: 'Convert images to PDF format' },
    { to: TOOL_PATHS.crop, icon: <LucideIcons.Crop className="w-6 h-6" />, title: 'Crop Image', description: 'Adjust image dimensions' },
    { to: TOOL_PATHS.compress, icon: <LucideIcons.Minimize2 className="w-6 h-6" />, title: 'Compress Image', description: 'Reduce image file size' },
    { to: TOOL_PATHS.resize, icon: <LucideIcons.Move className="w-6 h-6" />, title: 'Resize Image', description: 'Change image dimensions' },
  ];

  return (
    <>
      <Helmet>
        <title>ilikeimg - AI-Powered Image Tools | Free Online Photo Editor</title>
        <meta name="description" content="Transform your images with AI-powered tools. Free online image editor featuring upscaling, face swap, format conversion, and more." />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 py-12">
          {user && (
            <div className="mb-8 text-center">
              <h2 className="text-2xl font-bold text-gray-900">Welcome back, {user.username}! 👋</h2>
              <p className="text-gray-600">You have {user.credits} credits available today</p>
            </div>
          )}

          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              AI-Powered Image Tools,
              <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent"> Free to Use</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Transform your images with advanced AI technology and essential editing tools.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-4xl mx-auto">
            {tools.map((tool) => (
              <ToolCard key={tool.to} {...tool} />
            ))}
          </div>

          {!user && (
            <div className="text-center mt-16">
              <div className="inline-flex items-center bg-gradient-to-r from-purple-50 to-blue-50 px-6 py-3 rounded-lg">
                <LucideIcons.Sparkles className="w-5 h-5 text-purple-500 mr-2" />
                <span className="text-gray-700">
                  Get started with free tools today
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;