// constants.js
export const TOOL_PATHS = {
    removeText: '/remove-text',
    convertJpg: '/convert-to-JPG',
    convertPng: '/convert-to-png',
    convertWebp: '/convert-to-webp',
    convertPdf: '/convert-to-pdf',
    crop: '/image-crop',
    compress: '/image-compress',
    resize: '/image-resize',
  };