import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = {
    
    essentialTools: [
      { name: 'Convert to JPG', path: '/convert-to-JPG' },
      { name: 'Convert to PNG', path: '/convert-to-png' },
      { name: 'Compress Image', path: '/image-compress' },
      { name: 'Crop Image', path: '/image-crop' },
    ],
    otherTools: [
      { name: 'WebP Converter', path: '/convert-to-webp' },
      { name: 'PDF Converter', path: '/convert-to-pdf' },
      { name: 'Resize Image', path: '/image-resize' },
    ],
    company: [
      { name: 'About Us', path: '/about' },
      { name: 'Blog', path: '/blog' },
      { name: 'Contact us', path: '/contact' },
      { name: 'Cookie policy', path: '/cookies' },
      { name: 'Terms of Service', path: '/terms' },
      { name: 'Privacy Policy', path: '/privacy' },
    ]
  };

  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-5xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
         

          {/* Essential Tools */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 mb-4">
              Essential Tools
            </h3>
            <ul className="space-y-2">
              {footerLinks.essentialTools.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-sm text-gray-500 hover:text-purple-600"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Other Tools */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 mb-4">
              Other Tools
            </h3>
            <ul className="space-y-2">
              {footerLinks.otherTools.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-sm text-gray-500 hover:text-purple-600"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 mb-4">
              Company
            </h3>
            <ul className="space-y-2">
              {footerLinks.company.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-sm text-gray-500 hover:text-purple-600"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-100 mt-8 pt-8 text-center">
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
            <img
              src="/icon-32.png"
              alt="IlikeIMG Logo"
              className="h-4 w-4"
            />
            <span>© {currentYear} iLikeIMG • Free online image tools</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;