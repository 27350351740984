import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Menu,
  X,
  FileType,
  FileOutput,
  Settings,
  LogOut,
  Loader,
  Crown
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { TOOL_PATHS } from '../constants'; // Updated import

const Header = () => {
  const { user, logout, loading } = useContext(AuthContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Close menu when location changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      const mobileMenu = document.getElementById('mobile-menu');
      const menuButton = document.getElementById('mobile-menu-button');
      
      if (mobileMenuOpen && 
          mobileMenu && 
          !mobileMenu.contains(event.target) && 
          !menuButton.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };

    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      // Add escape key handler
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') setMobileMenuOpen(false);
      });
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
  }, [mobileMenuOpen]);

  const basicTools = [
    {
      to: TOOL_PATHS.convertJpg, // Updated to match the new structure
      icon: <FileType className="w-5 h-5" />,
      label: 'Convert to JPG',
      description: 'Convert images to JPEG format'
    },
    {
      to: TOOL_PATHS.compress, // Updated to match the new structure
      icon: <FileOutput className="w-5 h-5" />,
      label: 'Compress',
      description: 'Reduce file size'
    }
  ];

  const getAvatarColor = (username) => {
    const colors = ['bg-purple-100 text-purple-600', 'bg-blue-100 text-blue-600'];
    const index = username.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const handleLogout = async () => {
    try {
      await logout();
      setMobileMenuOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleMenuItemClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white border-b border-gray-200 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src="/icon-192.png"
              alt="IlikeIMG Logo"
              className="h-8 w-8"
            />
            <span className="ml-2 text-2xl font-bold bg-gradient-to-r from-purple-600 to-purple-500 bg-clip-text text-transparent">
              iLikeIMG
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex sm:items-center sm:space-x-6">
            {/* Tools as direct links */}
           
            {/* Auth Section */}
            {loading ? (
              <div className="flex items-center">
                <Loader className="w-5 h-5 text-purple-500 animate-spin" />
              </div>
            ) : user ? (
              <div className="flex items-center space-x-2">
                <div className={`h-8 w-8 rounded-full flex items-center justify-center font-medium ${getAvatarColor(user.username)}`}>
                  {user.username[0].toUpperCase()}
                </div>
                <div className="text-left">
                  <p className="text-sm font-medium text-gray-700">{user.username}</p>
                  {user.isPremium && (
                    <p className="text-xs text-purple-600 flex items-center">
                      <Crown className="w-3 h-3 mr-1" /> Premium
                    </p>
                  )}
                </div>
                <Link
                  to="/settings"
                  className="ml-4 p-2 text-gray-700 hover:text-purple-600 hover:bg-purple-50 rounded-md transition-colors"
                >
                  <Settings className="w-5 h-5" />
                </Link>
                <button
                  onClick={handleLogout}
                  className="p-2 text-gray-700 hover:text-purple-600 hover:bg-purple-50 rounded-md transition-colors"
                >
                  <LogOut className="w-5 h-5" />
                </button>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/auth"
                  state={{ isLogin: true }}
                  className="text-gray-700 hover:text-purple-600 transition-colors"
                >
                  Sign In
                </Link>
                <Link
                  to="/auth"
                  state={{ isLogin: false }}
                  className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-colors"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            id="mobile-menu-button"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="sm:hidden p-2 text-gray-500 hover:text-purple-600"
            aria-expanded={mobileMenuOpen}
            aria-controls="mobile-menu"
          >
            {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        id="mobile-menu"
        className={`fixed inset-y-0 right-0 transform ${
          mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } w-64 bg-white shadow-lg transition-transform duration-200 ease-in-out sm:hidden`}
      >
        <div className="px-4 py-6 space-y-6">
          {user && (
            <div className="flex items-center mb-6 pb-6 border-b border-gray-200">
              <div className={`h-10 w-10 rounded-full flex items-center justify-center font-medium ${getAvatarColor(user.username)}`}>
                {user.username[0].toUpperCase()}
              </div>
              <div className="ml-3">
                <p className="font-medium text-gray-900">{user.username}</p>
                {user.isPremium && (
                  <p className="text-sm text-purple-600 flex items-center">
                    <Crown className="w-4 h-4 mr-1" /> Premium
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="space-y-6">
            <div>
              <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-3">
                Basic Tools
              </h3>
              <div className="space-y-2">
                {basicTools.map((tool) => (
                  <Link
                    key={tool.to}
                    to={tool.to}
                    className="flex items-center px-3 py-2 rounded-md text-sm text-gray-700 hover:bg-purple-50"
                    onClick={handleMenuItemClick}
                  >
                    {tool.icon}
                    <span className="ml-3">{tool.label}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          {!loading && !user && (
            <div className="grid grid-cols-2 gap-4 mt-6 pt-6 border-t border-gray-200">
              <Link
                to="/auth"
                state={{ isLogin: true }}
                className="text-center py-2 text-purple-600 border border-purple-600 rounded-md"
                onClick={handleMenuItemClick}
              >
                Sign In
              </Link>
              <Link
                to="/auth"
                state={{ isLogin: false }}
                className="text-center py-2 bg-purple-600 text-white rounded-md"
                onClick={handleMenuItemClick}
              >
                Sign Up
              </Link>
            </div>
          )}

          {user && (
            <div className="border-t border-gray-200 pt-6">
              <Link
                to="/settings"
                className="flex items-center px-3 py-2 rounded-md text-sm text-gray-700 hover:bg-purple-50"
                onClick={handleMenuItemClick}
              >
                <Settings className="w-5 h-5 mr-3" />
                Settings
              </Link>
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-3 py-2 rounded-md text-sm text-gray-700 hover:bg-purple-50"
              >
                <LogOut className="w-5 h-5 mr-3" />
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;